import React, { useLayoutEffect, useRef } from "react";
import * as ClientDetailsStyles from "./clientDetails.module.scss";

import ClientDetailsImg from "../../../../assets/images/client-details-cover.png";
import ClientDetailsImg1 from "../../../../assets/images/client-details-1.png";
import ClientDetailsImg2 from "../../../../assets/images/client-details-2.png";

import ClientStoriesShortListing from "../ClientStoriesShortListing/ClientStoriesShortListing";

import { FadeInUPTextAnimation } from "../../../../../GSAPSnippets";

const ClientStoriesData = [
  {
    title: "A new life for IT Flakes",
    subtitle: "Business Continuity",
    link: "/client/it-flakes",
  },
  {
    title: "Challenging the impossible for DOK32",
    subtitle: "Brand Transformation",
    link: "/client/DOK32",
  },
];

const SmartBoardDetails = () => {
  useLayoutEffect(() => {
    FadeInUPTextAnimation("client-details-global-wrapper", 1.2);
  }, []);

  return (
    <section
      className={`${ClientDetailsStyles.ClientDetailsWrapper} client-details-global-wrapper`}
    >
      <span className="es-subtitle-text">
        Performance Optimization And More
      </span>
      <h1>Unlocking pure agility for Smartboard</h1>

      <div className={ClientDetailsStyles.clientDetailsCoverImg}>
        <img src={ClientDetailsImg} alt="A new life for IT Flakes" />
      </div>

      <div
        className={`${ClientDetailsStyles.ClientDetailsStory} story-text-wrapper`}
      >
        <div className={ClientDetailsStyles.ClientDetailsStoryContent}>
          <span className="story-text">The story</span>
          <p>
            The solar company Smartboard faced growth constraints when they came
            to us. Our experts enhanced their cloud infrastructure to match the
            desired scalability with maximum agility. Endshift enabled
            Smartboard to cut costs to the bare minimum.
          </p>
        </div>
      </div>

      <div className={ClientDetailsStyles.ClientDetailsAdditional}>
        <div className={ClientDetailsStyles.ClientDetailsGallery}>
          <div>
            <img src={ClientDetailsImg1} alt="" />
          </div>
          <div>
            <img src={ClientDetailsImg2} alt="" />
          </div>
        </div>

        <div className={ClientDetailsStyles.ClientDetailsAdditionalDesc}>
          <div className={ClientDetailsStyles.ClientDetailsStory}>
            <div className={ClientDetailsStyles.ClientDetailsStoryContent}>
              <span>In every way possible:</span>
              <p>
                Our team streamlined their operations, enhanced their security,
                migrated and integrated external data seamlessly to their system
                and eradicated the growth bottlenecks in their domain. We were
                able to help them save major chunks of their AWS bills and made
                future deployment a piece of cake for their team.
              </p>
            </div>
          </div>
          <div className={ClientDetailsStyles.ClientDetailsStory}>
            <div className={ClientDetailsStyles.ClientDetailsStoryContent}>
              <span>
                Complex projects no one wants to take? Send them our way.
              </span>
              <p>
                End Shift is the champion of complex projects. We helped with
                simplifying their challenges and enabling them seamless growth
                in the future. We take pride in delivering long-term solutions
                even when all else fails.
              </p>
            </div>
          </div>
          <div className={ClientDetailsStyles.ClientDetailsReview}>
            <div className={ClientDetailsStyles.ClientDetailsReviewContent}>
              <p>
                “I hired Atif to set up a worker server in AWS to perform long
                processes. Along the way he gave me some great advise and was
                able to tweak the RDS instance to speed up processing without
                increasing costs. I would classify Atif as a true expert in
                AWS-related services and optimization.”
              </p>
              <div className={ClientDetailsStyles.ClientDetailsReviewer}>
                <div>{/* <img src={Avatar} alt="Jimmy Kim" /> */}</div>
                <div>
                  <h6>Jay</h6>
                  <span>Founder/CEO of Smart Board</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={ClientDetailsStyles.ClientDetailsNext}>
        <span className="es-subtitle-text">Next Projects</span>
        <ClientStoriesShortListing ClientStoriesData={ClientStoriesData} />
      </div>
    </section>
  );
};

export default SmartBoardDetails;
